import {
  InboxIcon,
  MegaphoneIcon,
  ChartPieIcon,
  ArrowPathRoundedSquareIcon,
  UserGroupIcon,
  FunnelIcon,
  CodeBracketIcon
} from '@heroicons/react/24/outline'
import emailCampaign from '@/images/landing-page/undraw_newsletter_re_wrob.svg'
import campaignsSS from '@/images/landing-page/campaigns.png'
import analyticsSS from '@/images/landing-page/analytics.png'
import emaileditorSS from '@/images/landing-page/emaileditor.png'
import segmentSS from '@/images/landing-page/segmentation.png'
import heyEmail from '@/images/landing-page/hey_email.svg'
import analyticsImage from '@/images/landing-page/undraw_data_points_re_vkpq.svg'
import Image from 'next/image'
import automationSS from '@/images/screenshots/automation_ss.png'
import githublogo from '@/images/logos/github-mark.png'
import githubNameLogo from '@/images/logos/GitHub_Logo.png'
import apiSS from '@/images/screenshots/api.png'
import FeatureMarquee from './FeatureMarquee'
import EmailForm from './EmailForm'
export default function PrimaryFeatures() {
  return (
    <div
      className="relative overflow-hidden bg-white pb-32 pt-16"
      id="features"
    >
      <hr className="absolute top-0 z-10 w-full h-px border-none bg-gray-100" style={{ background: 'linear-gradient(90deg,rgba(225,223,237,0),#e1dfed 50%,rgba(225,223,237,0))' }} />

      <div className="mx-auto lg:text-center mb-16 px-10 text-center">
        <h2 className="mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our comprehensive email marketing services - <br/> all you need to kickstart success!
        </h2>
      </div>
      <FeatureMarquee></FeatureMarquee>
      {/* <hr className=" my-12 top-0 z-10 w-full h-px border-none bg-gray-100" style={{ background: 'linear-gradient(90deg,rgba(225,223,237,0),#e1dfed 50%,rgba(225,223,237,0))' }} /> */}
      {/* <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div>
                <MegaphoneIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  CampaignHQ allows you to send email campaigns to your entire
                  list of subscribers at once, or you can segment your list and
                  send targeted emails to specific segments of your list.
                </p>
                <div className="mt-6 flex">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 flex items-center justify-center pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <Image
                src={campaignsSS}
                alt="Campaigns Screenshots"
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-[452px] lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-16">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32 relative">
            <div className="absolute z-0 transform-gpu overflow-hidden blur-3xl bottom-0 left-0 w-full">
              <svg
                className="relative h-[21.1875rem] max-w-none translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-40rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
              >
                <path
                  fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                  fillOpacity=".3"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset={1} stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className='z-10 relative'>
              {/* <div>
                <ChartPieIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div> */}
              <div className="mt-6">
                <h2 className="text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">
                  Craft Irresistible Emails with Ease!
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Create captivating emails effortlessly using CampaignHQ's intuitive drag-and-drop editor. No coding knowledge is needed! For advanced customization, utilize our HTML editor to craft your perfect emails.
                </p>
                <div className="mt-6 flex">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 flex items-center justify-center pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              {/* <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                alt="Customer profile user interface"
              /> */}
              <Image
                src={emaileditorSS}
                alt="Affordable email marketing tool"
                className="w-full rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-[452px] lg:w-auto lg:max-w-none"
              />
              {/* <Image src={analyticsImage} alt="Logo" unoptimized height={300} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-10">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16 relative">
            <div className="z-0 absolute transform-gpu overflow-hidden blur-3xl bottom-0 left-0 w-full">
              <svg
                className="relative -z-10 h-[21.1875rem] max-w-none translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-40rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
              >
                <path
                  fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                  fillOpacity=".3"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset={1} stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className='relative z-10'>
              {/* <div>
                <UserGroupIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div> */}
              <div className="mt-6">
                <h2 className="text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">
                  Unleash email marketing insights: Advanced Analytics & Reporting Suite
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Achieve email marketing excellence with CampaignHQ's robust analytics and reporting suite. Track key performance metrics like open rate, click rate, and unsubscribe rate, gaining valuable insights to optimize your campaigns for success.
                </p>
                <div className="mt-6 flex">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 relative">
            <div className="-mr-48 flex items-center justify-center pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              {/* <Image src={heyEmail} alt="Logo" unoptimized height={300} /> */}
              <Image
                src={analyticsSS}
                alt="Email marketing services"
                height={452}
                className="w-full rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-[452px] lg:w-auto lg:max-w-none"
              />
              {/* <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                alt="Inbox user interface"
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
            <div>
              <div>
                <FunnelIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Target Your Subscribers with Segmentation Feature
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  CampaignHQ comes with a powerful segmentation feature that
                  allows you to segment your subscribers based on their
                  behavior, such as when they sign up for your email list, make
                  a purchase, or abandon their cart.
                </p>
                <div className="mt-6">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 flex items-center justify-center pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <Image
                src={segmentSS}
                alt="CampaignHQ segmentation screenshot"
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-[470px] lg:w-auto lg:max-w-none"
              />

            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="relative mt-10">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div>
                <ArrowPathRoundedSquareIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Setup automated workflows and make your email marketing more efficient
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  CampaignHQ allows you to create automated workflows that will
                  send emails to your subscribers based on their behavior, such
                  as when they sign up for your email list, make a purchase, or
                  abandon their cart.
                </p>
                <div className="mt-6 flex">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 flex items-center justify-center pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <Image
                src={automationSS}
                alt="Automation Screenshots"
                height={452}
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-[452px] lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-24 relative z-10">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
            <div>
              {/* <div className='flex space-x-10'>
                <ArrowPathRoundedSquareIcon
                  className="h-12 w-12 text-indigo-600"
                  aria-hidden="true"
                />
              </div> */}
              <div className="mt-6">
                <h2 className="text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">
                  Take your email marketing efficiency to the next level with CampaignHQ's automated workflows!
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Seamlessly set up personalized email sequences triggered by your subscribers' behavior – whether they sign up, make a purchase, or abandon their cart. Maximize engagement and drive results effortlessly!
                </p>
                <div className="mt-6 flex space-x-10 items-center">
                  <a
                    href="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try for free <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 flex items-center justify-center pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <Image
                src={automationSS}
                alt="Email marketing service provider in India"
                className="w-full rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-[470px] lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <FeatureMarquee></FeatureMarquee> */}
      <div className="z-0 absolute transform-gpu overflow-hidden blur-3xl bottom-0 right-0 w-full">
        <svg
          className="relative -z-10 h-[21.1875rem] max-w-none translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-40rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}
